import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { useSideDrawer } from '@/utils/hooks/useSideDrawer';
import { CloseButton, DrawerContent, Flex, Image } from '@chakra-ui/react';
import NavigationContent from '../NavigationContent';
import BottomNavigation from './internal/BottomNavigation';

const InternalMobileNavigation = () => {
  const {
    open: openDrawer,
    close: closeDrawer,
    element: Drawer,
  } = useSideDrawer({
    Component: DrawerNavigationContent,
  });
  return (
    <>
      <SuspenseWithSpinner>
        <BottomNavigation onClickOther={() => openDrawer({ onClose: () => closeDrawer() })} />
      </SuspenseWithSpinner>
      {Drawer}
    </>
  );
};

const DrawerNavigationContent = ({ onClose }: { onClose: () => void }) => {
  return (
    <DrawerContent bg='neutral.100'>
      <Flex h='10' alignItems='center' mx='5' justifyContent='space-between'>
        <Image
          cursor='pointer'
          src='/logo.png'
          alt='M2X'
          // チラつくのでcssで直接指定
          style={{ maxWidth: '100px' }}
          width='100px'
          height='14px'
        />
        <CloseButton onClick={() => onClose()} />
      </Flex>
      <SuspenseWithSpinner>
        <NavigationContent onItemClick={() => onClose()} />
      </SuspenseWithSpinner>
    </DrawerContent>
  );
};

export default InternalMobileNavigation;
